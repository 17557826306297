
import { HashRouter, Route, Routes } from 'react-router-dom';
import { lazy, Suspense } from 'react';
import { ErrorBoundary } from "react-error-boundary";
import { CssBaseline, ThemeProvider, useTheme } from '@mui/material';

import PlusJakartaSans from './fonts/PlusJakartaSans[wght].woff'


const PlusJakartaSansOptions = {
  fontFamily: 'Plus Jakarta Sans Regular',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 'normal',
  src: `
   local('Plus Jakarta Sans Regular'),
   url(${PlusJakartaSans}) format('woff')
 `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const LoginComponent = lazy(() => import('./Login'))
const SignupComponent = lazy(() => import('./Signup'))
const ForgotPasswordComponent = lazy(() => import('./ForgotPassword'))
const ClientComponent = lazy(() => import('./client/index'))
const CustomerComponent = lazy(() => import('./customer/index'))
const AdminComponent = lazy(() => import('./admin/index'))
const HomeComponent = lazy(() => import('./Home'))
const ErrorBoundaryComponent = lazy(() => import("./helper/Error"))


function App() {

  const theme = useTheme({
    typography: {
      fontFamily: ['"Open Sans"', 'Plus Jakarta Sans Regular', 'Roboto'].join(','),
    },
    components: {
     MuiCssBaseline: {
      '@global': {
        '@font-face': [PlusJakartaSansOptions],
      },
    }
    }
  })
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className='App'>

        <HashRouter>
          <ErrorBoundary FallbackComponent={<ErrorBoundaryComponent />}>
            <Suspense fallback={<>Loading..</>}>
              <Routes>
                <Route exact path="/" element={<HomeComponent />} />
                <Route exact path="/login" element={<LoginComponent />} />
                <Route exact path="/register" element={<SignupComponent />} />
                <Route exact path="/forgot-password" element={<ForgotPasswordComponent />} />
                <Route exact path='/client' element={<ClientComponent />} />
                <Route exact path='/customer' element={<CustomerComponent />} />
                <Route exact path='/admin' element={<AdminComponent />} />
                <Route path="*" element={<HomeComponent />} />
              </Routes>
            </Suspense>
          </ErrorBoundary>
        </HashRouter>

      </div>
    </ThemeProvider>

  );
}

export default App;
