import { createContext, useRef, useState } from "react";

export const SaasContext = createContext()

// Every state should be initialized here
const INITIAL_STATE = {
    isLeftMenuOpen: true
}
const SaasContextProvider = ({ children }) => {


    /**
     * ::NOTE::
     * Only use another useState if it requires state to be handled differently by any components
     * Otherwise, only use the same initial state in whole code for consistency
     */

    // State initialization and reference for the state object
    const [state, setState] = useState(INITIAL_STATE)
    const ref = useRef(state)
    

    // useEffect(() => {
    //     console.log(ref, setState(true))
    // }, [])

    const showLeftMenu = (value) => {
        console.log(ref.current)
        setState((preState) => ({
            ...preState,
            isLeftMenuOpen: value
        }))
    }

    /**
     * Every function that is made here should only have single work to do
     * It must have proper comments at the start with the parameters and return values predefined
     * 
     * @param - for the parameters accepted by the function with proper comments
     * @returns - for the return values from the function
     * @summary - for the brief summary of the function implementation
     */






    // Every function which needs to be access from different files should go inside the value object
    const value = {
        state,
        showLeftMenu
    }

    // Return Value of the SaaS Context Object
    return (
        <div>
            <SaasContext.Provider value={value}>
                {children}
            </SaasContext.Provider>
        </div>
    );
}

export default SaasContextProvider